<template>
  <InlineSvg
    height="32"
    :class="[$style[color]]"
    :src="require('@/assets/logo.svg')"
    aria-label="Klarbuss"
  />
</template>

<script lang="ts">
import InlineSvg from 'vue-inline-svg'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppLogo',

  components: {
    InlineSvg,
  },

  props: {
    color: {
      type: String,
      default: 'white',
      validator: (color: string) => ['white', 'black'].includes(color),
    },
  },
})
</script>

<style lang="scss" module>
.white path {
  fill: white;
}

.black path {
  fill: black;
}
</style>
