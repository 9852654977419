<template>
  <div>
    <AppErrorModal />
    <RouterView v-slot="{ Component }">
      <transition
        enter-active-class="animated animate__fadeIn"
        leave-active-class="animated animate__fadeOut"
        mode="out-in"
        :duration="140"
      >
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppErrorModal from '@/components/AppErrorModal.vue'

export default defineComponent({
  name: 'App',

  components: {
    AppErrorModal,
  },

  setup() {
    return {}
  },
})
</script>
