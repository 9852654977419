<template>
  <q-btn-dropdown
    no-caps
    flat
    class="text-black"
    :label="profile.email"
    content-class="no-border-radius"
  >
    <q-list style="min-width: 100px">
      <q-item clickable v-close-popup @click="signOut">
        <q-item-section avatar>
          <q-icon size="xs" name="mdi-logout-variant" />
        </q-item-section>
        <q-item-section>Logga ut</q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script lang="ts">
import { useProfile } from '@/composables/useProfile'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppAccountDropdown',

  setup() {
    return useProfile()
  },
})
</script>
