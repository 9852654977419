import { render } from "./AppErrorModal.vue?vue&type=template&id=71069034"
import script from "./AppErrorModal.vue?vue&type=script&lang=ts"
export * from "./AppErrorModal.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./AppErrorModal.vue?vue&type=style&index=0&id=71069034&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QIcon,QSeparator,QCardActions,QBtn});qInstall(script, 'directives', {ClosePopup});
