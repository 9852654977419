import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { SENTRY_DSN } from './config'
import { useError } from './composables/useError'

const { applicationErrorHandler } = useError()

const app = createApp(App)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

app.config.errorHandler = applicationErrorHandler

app.use(Quasar, quasarUserOptions)
app.use(router)
app.mount('#app')
