import { render } from "./AppTopBar.vue?vue&type=template&id=b345046a"
import script from "./AppTopBar.vue?vue&type=script&lang=ts"
export * from "./AppTopBar.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QToolbarTitle,QSpace});
