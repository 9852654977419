import { render } from "./AppDrawerMobile.vue?vue&type=template&id=0af84674"
import script from "./AppDrawerMobile.vue?vue&type=script&lang=ts"
export * from "./AppDrawerMobile.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QScrollArea,QIcon,QSeparator,QList,QItem,QItemSection});qInstall(script, 'directives', {Ripple});
