<template>
  <q-toolbar class="q-py-md">
    <AppLogo color="black" />
    <q-toolbar-title class="text-black">Klarbuss</q-toolbar-title>
    <q-space />
    <AppAccountDropdown />
  </q-toolbar>
</template>

<script lang="ts">
import AppAccountDropdown from '@/components/AppAccountDropdown.vue'
import { defineComponent } from 'vue'
import AppLogo from '@/components/AppLogo.vue'

export default defineComponent({
  name: 'AppTopBar',

  components: {
    AppLogo,
    AppAccountDropdown,
  },
})
</script>
