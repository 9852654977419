import axios from 'axios'
import { API_HOST } from '../config'
import { useProfile } from '@/composables/useProfile'
import { useError } from '@/composables/useError'

const { getToken, isSignedIn, signOut } = useProfile()
const { axiosErrorHandler } = useError()
const CancelToken = axios.CancelToken

export const instance = axios.create({
  baseURL: API_HOST,
  headers: {},
})

instance.interceptors.request.use(async (config) => {
  const signedIn = await isSignedIn()

  if (!signedIn) {
    signOut()
    return {
      ...config,
      cancelToken: new CancelToken((cancel) => cancel('Not signed in')),
    }
  }

  config.headers.Authorization = `Bearer ${getToken()}`
  return config
}, axiosErrorHandler)

instance.interceptors.response.use((response) => response, axiosErrorHandler)
