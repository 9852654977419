import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut as authSignOut,
  onAuthStateChanged,
} from 'firebase/auth'
import { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, TOKEN_NAME } from '@/config'
import { getUserInfo } from '@/api/iam/getUserInfo'
import { Ref, ref } from 'vue'
import { UserInfo } from '@/types/user-info'

interface UseProfile {
  profile: Ref<UserInfo | null>
  signInPending: Ref<boolean>
  signOutPending: Ref<boolean>
  signIn: (userCredentials: UserCredentials) => void
  signOut: () => Promise<null>
  getToken: () => string | null
  isSignedIn: () => Promise<boolean>
  fetchUserInfo: () => Promise<UserInfo>
  can: (permission: string) => boolean
}

interface UserCredentials {
  email: string
  password: string
}

const config = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
}

initializeApp(config)
const auth = getAuth()
const profile = ref<UserInfo | null>(null)
const signInPending = ref(false)
const signOutPending = ref(false)

export function useProfile(): UseProfile {
  function updateToken(token: string | null) {
    if (!token) {
      return window.localStorage.removeItem(TOKEN_NAME)
    }
    window.localStorage.setItem(TOKEN_NAME, token)
  }

  function getToken() {
    return window.localStorage.getItem(TOKEN_NAME)
  }

  async function fetchUserInfo() {
    return new Promise<UserInfo>((resolve, reject) => {
      getUserInfo()
        .then(({ data }) => {
          updateProfile(data)
          resolve(data)
        })
        .catch(reject)
    })
  }

  async function isSignedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          updateToken(null)
          resolve(false)
        } else {
          user
            .getIdTokenResult()
            .then(({ token }) => {
              updateToken(token)
              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        }
      })
    })
  }

  async function signIn({ email, password }: UserCredentials) {
    signInPending.value = true

    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then(async ({ user }) => {
          if (!user) {
            throw new Error('No user found')
          }
          return user.getIdTokenResult().then(({ token }) => token)
        })
        .then(updateToken)
        .then(resolve)
        .catch(reject)
        .finally(() => {
          signInPending.value = false
        })
    })
  }

  async function signOut() {
    return new Promise<null>((resolve, reject) => {
      authSignOut(auth)
        .then(() => {
          updateToken(null)
          window.location.pathname = '/sign-in'
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          signInPending.value = false
        })
    })
  }

  function updateProfile(profileData: UserInfo | null): void {
    profile.value = profileData
  }

  function can(permission: string) {
    if (!profile.value) return false

    return profile.value.permissions.includes(permission)
  }

  return {
    can,
    signOut,
    profile,
    signInPending,
    signOutPending,
    signIn,
    getToken,
    isSignedIn,
    fetchUserInfo,
  }
}
