
import AppAccountDropdown from '@/components/AppAccountDropdown.vue'
import { defineComponent } from 'vue'
import AppLogo from '@/components/AppLogo.vue'

export default defineComponent({
  name: 'AppTopBar',

  components: {
    AppLogo,
    AppAccountDropdown,
  },
})
