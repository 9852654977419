
import { defineComponent } from 'vue'
import AppErrorModal from '@/components/AppErrorModal.vue'

export default defineComponent({
  name: 'App',

  components: {
    AppErrorModal,
  },

  setup() {
    return {}
  },
})
