
import AppLogo from '@/components/AppLogo.vue'
import AppDrawerMobile from '@/components/AppDrawerMobile.vue'
import { computed, defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'LayoutMobile',

  components: {
    AppDrawerMobile,
    AppLogo,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const drawerRight = ref(false)
    const showBackButton = computed(() => {
      return route.name !== 'Ramp'
    })

    function goBack() {
      router.back()
    }

    return { showBackButton, goBack, drawerRight }
  },
})
