
import { defineComponent } from 'vue'
import { useError } from '@/composables/useError'

export default defineComponent({
  name: 'AppErrorModal',

  setup() {
    return useError()
  },
})
