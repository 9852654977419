
import { defineComponent } from 'vue'
import AppTopBar from '@/components/AppTopBar.vue'

export default defineComponent({
  name: 'LayoutDesktop',

  components: {
    AppTopBar,
  },
})
