
import { useProfile } from '@/composables/useProfile'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppAccountDropdown',

  setup() {
    return useProfile()
  },
})
