<template>
  <q-layout>
    <q-header class="bg-white" elevated>
      <AppTopBar />
    </q-header>

    <q-page-container>
      <RouterView v-slot="{ Component }">
        <transition
          enter-active-class="animated animate__fadeIn"
          leave-active-class="animated animate__fadeOut"
          mode="out-in"
          :duration="140"
        >
          <component :is="Component" />
        </transition>
      </RouterView>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppTopBar from '@/components/AppTopBar.vue'

export default defineComponent({
  name: 'LayoutDesktop',

  components: {
    AppTopBar,
  },
})
</script>
