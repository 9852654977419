import axios, { AxiosError } from 'axios'
import { Ref, ref, watch, ComponentPublicInstance } from 'vue'

interface UseError {
  showErrorModal: Ref<boolean>
  axiosErrorHandler: (error: AxiosError) => Promise<AxiosError>
  applicationErrorHandler: (
    error: unknown,
    vm?: ComponentPublicInstance | null,
    info?: string
  ) => void
  errorRequest: Ref<ErrorRequest | null>
  errorResponse: Ref<ErrorResponse | null>
  errorApplication: Ref<ErrorApplication | null>
}

interface ErrorApplication {
  error: string | null
  vm: string | null
  info: string | null
}

interface ErrorRequest {
  url: string | null
  method: string | null
  data: string | null
}

interface ErrorResponse {
  status: number | null
  data: string | null
  statusText: string | null
}

const showErrorModal = ref(false)
const errorRequest = ref<ErrorRequest | null>(null)
const errorResponse = ref<ErrorResponse | null>(null)
const errorApplication = ref<ErrorApplication | null>(null)

watch(showErrorModal, (value) => {
  if (!value) {
    errorRequest.value = null
    errorResponse.value = null
    errorApplication.value = null
  }
})

export function useError(): UseError {
  async function axiosErrorHandler(
    errorObject: AxiosError
  ): Promise<AxiosError> {
    if (axios.isCancel(errorObject)) {
      return Promise.resolve(errorObject)
    }
    if (axios.isAxiosError(errorObject)) {
      errorRequest.value = {
        url: `${errorObject.config.baseURL}${errorObject.config.url}`,
        method: errorObject.config.method || null,
        data: errorObject.config.data || null,
      }

      if (errorObject.response) {
        errorResponse.value = {
          status: errorObject.response.status,
          statusText: errorObject.response.statusText,
          data: errorObject.response.data,
        }
      }

      showErrorModal.value = true
    } else {
      applicationErrorHandler(errorObject)
    }

    return Promise.reject(errorObject)
  }

  function applicationErrorHandler(
    error: unknown,
    vm: ComponentPublicInstance | null = null,
    info: string | null = null
  ) {
    errorApplication.value = { info: null, error: null, vm: null }
    if (error instanceof Error) {
      errorApplication.value.error = error.message
    }
    if (typeof error === 'string') {
      errorApplication.value.error = error
    }

    if (vm) {
      errorApplication.value.vm = vm.$options.name || null
    }

    errorApplication.value.info = info

    showErrorModal.value = true
  }

  return {
    showErrorModal,
    axiosErrorHandler,
    errorRequest,
    errorResponse,
    applicationErrorHandler,
    errorApplication,
  }
}
