
import InlineSvg from 'vue-inline-svg'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppLogo',

  components: {
    InlineSvg,
  },

  props: {
    color: {
      type: String,
      default: 'white',
      validator: (color: string) => ['white', 'black'].includes(color),
    },
  },
})
