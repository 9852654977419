import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useProfile } from '@/composables/useProfile'
import LayoutDesktop from '@/layouts/LayoutDesktop.vue'
import LayoutMobile from '@/layouts/LayoutMobile.vue'

const { isSignedIn, signOut, profile, fetchUserInfo, can } = useProfile()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'root',
    redirect: '/sign-in',
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    meta: {
      header: 'Logga in',
    },
    component: () =>
      import(/* webpackChunkName: "sign-in" */ '@/pages/SignIn.vue'),
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: LayoutMobile,
    children: [
      {
        path: '',
        alias: 'ramp',
        name: 'Ramp',
        meta: {
          header: 'Välj ramp',
          permission: 'mobile.view',
        },
        component: () =>
          import(/* webpackChunkName: "ramp" */ '../pages/mobile/Ramp.vue'),
      },
      {
        path: 'wash-list/:rampUuid/:trafficDate',
        name: 'WashListMobile',
        meta: {
          header: 'Tvättlista',
          permission: 'mobile.view',
        },
        component: () =>
          import(
            /* webpackChunkName: "wash-list-mobile" */ '../pages/mobile/WashList.vue'
          ),
      },
      {
        path: 'checklist/:rampUuid/:trafficDate/:arrivalUuid',
        name: 'Checklist',
        meta: {
          header: 'Checklista',
          permission: 'mobile.view',
        },
        component: () =>
          import(
            /* webpackChunkName: "checklist" */ '../pages/mobile/Checklist.vue'
          ),
      },
    ],
  },
  {
    path: '/backoffice',
    name: 'Backoffice',
    component: LayoutDesktop,
    children: [
      {
        path: '',
        name: 'WashListBackoffice',
        meta: {
          header: 'Tvättlista',
          permission: 'backoffice.view',
        },
        component: () =>
          import(
            /* webpackChunkName: "wash-list-backoffice" */ '../pages/backoffice/WashList.vue'
          ),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to) => {
  const signedIn = await isSignedIn()

  if (!signedIn && to.name !== 'sign-in') signOut()

  if (signedIn && !profile.value) {
    try {
      await fetchUserInfo()
    } catch (error) {
      signOut()
    }
  }

  if (to.meta?.permission && !can(to.meta.permission)) {
    signOut()
  }

  document.title = `Klarbuss | ${to?.meta?.header || ''}`
})

export default router
