<template>
  <q-layout view="hHr lpR fFr">
    <q-header
      class="bg-white"
      :elevated="$route.name !== 'WashListMobile'"
      :bordered="$route.name === 'WashListMobile'"
    >
      <q-toolbar>
        <div class="flex justify-between full-width items-center">
          <q-btn
            flat
            dense
            round
            icon="mdi-arrow-left"
            color="black"
            v-if="showBackButton"
            @click="goBack"
          />
          <AppLogo color="black" v-else />
          <div>
            <q-toolbar-title class="text-subtitle1 text-black">{{
              $route.meta.header
            }}</q-toolbar-title>
          </div>
          <q-btn
            flat
            color="black"
            @click="drawerRight = !drawerRight"
            round
            dense
            icon="mdi-menu-open"
          />
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
      side="right"
      v-model="drawerRight"
      bordered
      :width="220"
      :breakpoint="500"
      content-class="bg-grey-3"
    >
      <AppDrawerMobile />
    </q-drawer>

    <q-page-container class="bg-grey-2">
      <RouterView v-slot="{ Component }">
        <transition
          enter-active-class="animated animate__fadeIn"
          leave-active-class="animated animate__fadeOut"
          mode="out-in"
          :duration="140"
        >
          <component :is="Component" :key="Component.name" />
        </transition>
      </RouterView>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import AppLogo from '@/components/AppLogo.vue'
import AppDrawerMobile from '@/components/AppDrawerMobile.vue'
import { computed, defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'LayoutMobile',

  components: {
    AppDrawerMobile,
    AppLogo,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const drawerRight = ref(false)
    const showBackButton = computed(() => {
      return route.name !== 'Ramp'
    })

    function goBack() {
      router.back()
    }

    return { showBackButton, goBack, drawerRight }
  },
})
</script>
