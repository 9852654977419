import { render } from "./LayoutDesktop.vue?vue&type=template&id=ba2477e6"
import script from "./LayoutDesktop.vue?vue&type=script&lang=ts"
export * from "./LayoutDesktop.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QPageContainer});
